<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="el-main a-fs-14 projectadd">
			<div class="fnW600 font18 mgb36">员工信息</div>
			<el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="mini"
				label-position="left">
				<el-form-item label="姓名" prop="userName">
					<el-input v-model="form.userName" class="w300" placeholder="请输入员工姓名"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="mobile">
					<el-input v-model="form.mobile" maxlength="11" class="w300" placeholder="请输入联系电话"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="form.password" type="password" maxlength="20" class="w300" placeholder="请设置密码">
					</el-input>
				</el-form-item>
				<el-form-item label="标签" prop="remark">
					<el-input v-model="form.remark" class="w300" placeholder="请输入员工标签"></el-input>
				</el-form-item>
				<el-form-item label="所属商户" prop="companyId">
					<le-company-under-select v-model="form.companyId" @input="companyBack" class="companyClass">
					</le-company-under-select>
				</el-form-item>
				<el-form-item label="所在部门" prop="dep">
					<el-select v-model="form.dep" placeholder="请选择员工类型">
						<el-option v-for="(item,index) in depList" :label="item" :value="item" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="过期时间" prop="disableTime">
					<el-date-picker v-model="form.disableTime" value-format="yyyy-MM-dd" type="date" :editable="false"
						placeholder="选择日期时间"></el-date-picker>
				</el-form-item>

				<el-form-item label="账号状态" prop="isValid">
					<el-switch v-model="form.isValid" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
				<el-form-item label="数据权限" prop="dataPermission" v-if="userInfo.dataPermission == 99">
					<el-radio-group v-model="form.dataPermission">
						<el-radio :label="1">普通用户</el-radio>
						<el-radio :label="99">超级管理员</el-radio>
						<el-radio :label="88">一级管理员</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="登录平台" prop="platform">
					<el-checkbox-group v-model="platform" @change="changePlatform">
						<el-checkbox label="1">WEB端</el-checkbox>
						<el-checkbox label="2">微信小程序端</el-checkbox>
						<el-checkbox label="3">微信运维端</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<div class="pdb30" v-if="hasWeb">
					<Rolecheck title="WEB端权限分配" type="web" :companyId="form.companyId" :list="roleData.webList"
						@input="getWebData"></Rolecheck>
				</div>
				<div class="pdb30" v-if="hasWx">
					<Rolecheck title="微信小程序端权限分配" type="wx" :companyId="form.companyId" :list="roleData.wxList"
						@input="getWxData"></Rolecheck>
				</div>
                <div class="pdb30" v-if="hasDevops">
					<Rolecheck title="微信运维端权限分配" type="devops" :companyId="form.companyId" :list="roleData.devopsList"
						@input="getDevopsData"></Rolecheck>
				</div>
				<div class="flex_start">
					<el-button type="primary" @click="onSubmit">确认编辑</el-button>
					<el-button @click="handlerCancel">&nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
				</div>
			</el-form>

		</el-card>
	</div>
</template>

<script>
	import Rolecheck from "./child/role-check.vue"; //员工列表
	import validate from '../../utils/validate'
	import util from '../../utils/util'
	import { mapState } from 'vuex';
	export default {
		components: {
			Rolecheck
		},
		computed: {
			hasWeb() {
				let hasData = this.platform.find(val => val == 1) && true
				return hasData
			},
			hasWx() {
				let hasData = this.platform.find(val => val == 2) && true
				return hasData
			},
            hasDevops () {
                let hasData = this.platform.find(val => val == 3) && true
				return hasData
            },
			...mapState({
                userInfo: state => state.user.user,
            })
		},
		data() {
			var validateName = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('用户姓名不能为空'))
				} else {
					callback()
				}
			}
			var validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('手机号不能为空'))
				} else if (!validate.isvalidPhone(value)) {
					callback(new Error('请输入正确的手机号'))
				} else {
					callback()
				}
			}
			var validateCompany = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请选择所属商户'))
				} else {
					callback()
				}
			}
			return {
				form: {
					"id": null, //更新时传
					"userName": "", //用户姓名
					"mobile": "", //电话
					"password": "",
					companyId: null,
					"dep": '',
					"remark": "", //标签
					"disableTime": "",
					"isValid": 0,
					"canLoginWxApp": 0,
					"canLoginWeb": 0,
                    "canLoginDevOpsApp": 0,
					"roleIds": [],
					userId: null,
					dataPermission: 1
				},
				rules: {
					userName: [{
						validator: validateName,
						trigger: "blur"
					}],
					mobile: [{
						validator: validatePhone,
						trigger: 'blur'
					}],
					companyId: [{
						validator: validateCompany,
						trigger: 'change'
					}]
				},
				platform: [],
				roleData: {
					webList: [],
					wxList: [],
                    devopsList: []
				},
				webList: [],
				wxList: [],
                devopsList: [],
				depList: []
			}
		},
		created() {
			if (this.$route.query.id) {
				this.form.id = parseInt(this.$route.query.id)
				this.form.userId = parseInt(this.$route.query.userId)
				this.form.companyId = parseInt(this.$route.query.companyId)
				this.getDetail()
				this.getDepList()
			}
		},
		methods: {
			handlerCancel() {
				this.$store.dispatch("remove_current_view", this)
			},
			onSubmit() {
				this.$refs['form'].validate(async valid => {
					if (valid) {
						if (this.platform.length) {
							this.form.canLoginWeb = this.platform.find(val => val == 1) ? 1 : 0
							this.form.canLoginWxApp = this.platform.find(val => val == 2) ? 1 : 0
							this.form.canLoginDevOpsApp = this.platform.find(val => val == 3) ? 1 : 0
						}
						let conArr = []
						if (this.webList.length) {
							for (let i = 0; i < this.webList.length; i++) {
								const element = this.webList[i];
								conArr.push(element.id)
							}
						}
						if (this.wxList.length) {
							for (let i = 0; i < this.wxList.length; i++) {
								const element = this.wxList[i];
								conArr.push(element.id)
							}
						}
                        if (this.devopsList.length) {
							for (let i = 0; i < this.devopsList.length; i++) {
								const element = this.devopsList[i];
								conArr.push(element.id)
							}
						}
						this.form.roleIds = conArr
						this.$Axios._post({
							url: this.$Config.apiUrl.saveRbacUser,
							params: this.form
						}).then(res => {
							this.$message.success('操作成功!')
							this.$store.dispatch("remove_current_view", this)
						})
					}
				});
			},
			//选中web权限
			getWebData(data) {
				this.webList = data
			},
			//选中wx权限
			getWxData(data) {
				this.wxList = data
			},
            //选中运维权限
			getDevopsData(data) {
				this.devopsList = data
			},
			//平台变化
			changePlatform(e) {
				let hasData = e.find(val => val == 1) && true
				if (!hasData) {
					this.webList = []
				}
				let hasData2 = e.find(val => val = 2) && true
				if (!hasData2) {
					this.wxList = []
				}
                let hasData3 = e.find(val => val = 3) && true
				if (!hasData3) {
					this.devopsList = []
				}
			},
			//获取详情
			getDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getCompanyUserDetail,
					params: {
						userId: this.form.userId,
						companyId: this.form.companyId
					}
				}).then(({
					data
				}) => {
					let disableTime = util.easyformatDate(data.disableTime)
					Object.assign(this.form, {
						...data,
						disableTime: disableTime
					})
                    console.log(data,1111);
					if (data.canLoginWeb == 1) {
						this.platform.push("1")
						this.getWebModuleUserRole()
					}
					if (data.canLoginWxapp == 1) {
						this.platform.push("2")
						this.getWxAppModuleUserRole()
					}
                    if (data.canLoginDevOpsApp == 1) {
						this.platform.push("3")
						this.getDevopsAppModuleUserRole()
					}
				})
			},
			//获取用户web权限
			getWebModuleUserRole() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getWebModuleUserRole,
					params: {
						userId: this.form.userId,
						companyId: this.form.companyId
					}
				}).then(({
					data
				}) => {
					let p_arr = data.filter(item => item.userHasThisRole)
					if (p_arr.length) {
						let n_arr = p_arr.map(item => item.userHasThisRole ? item.roleId : '')
						this.roleData.webList = n_arr
					}
				})
			},
			//获取用户wx权限
			getWxAppModuleUserRole() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getWxAppModuleUserRole,
					params: {
						userId: this.form.userId,
						companyId: this.form.companyId
					}
				}).then(({
					data
				}) => {
					let p_arr = data.filter(item => item.userHasThisRole)
					if (p_arr.length) {
						let n_arr = p_arr.map(item => item.userHasThisRole ? item.roleId : '')
						this.roleData.wxList = n_arr
					}
				})
			},
            //获取用户运维权限
            getDevopsAppModuleUserRole () {
                this.$Axios._get({
					url: this.$Config.apiUrl.getDevopsAppModuleUserRole,
					params: {
						userId: this.form.userId,
						companyId: this.form.companyId
					}
				}).then(({
					data
				}) => {
					let p_arr = data.filter(item => item.userHasThisRole)
					if (p_arr.length) {
						let n_arr = p_arr.map(item => item.userHasThisRole ? item.roleId : '')
						this.roleData.devopsList = n_arr
					}
				})
            },
			getDepList() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getDepList,
				}).then(({
					data
				}) => {
					this.depList = data
				})
			},
			companyBack() {},

		}
	}
</script>

<style lang="scss" scoped>
	.companyClass {
		padding: 0 !important;

		/deep/ .a-ml-20 {
			margin-left: 0;
		}
	}
</style>
